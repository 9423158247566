$(document).ready(function () {
    //Toggle team s2
    $(".collapse-team-s2").on("click", function () {
        $(this).addClass("d-none");
    });

    $(".team-style2 .opened").on("click", function () {
        if ($('#team-s2').hasClass('collapsing')) {
            setTimeout(function () {
                $("#team-s2").collapse('hide');
                setTimeout(function () {
                    $(".collapse-team-s2").removeClass("d-none");
                }, 350);
            }, 350);

            return;
        }

        $("#team-s2").collapse('hide');
        setTimeout(function () {
            $(".collapse-team-s2").removeClass("d-none");
        }, 350);
    });

    //Ticker
    var outer = document.querySelector("#outer");
    if (
        document.querySelector("#outer") != null &&
        outer.querySelector("#content") != null
    ) {
        var content = outer.querySelector("#content");
        repeatContent(content, outer.offsetWidth);

        var el = outer.querySelector("#loop");
        el.innerHTML = el.innerHTML + el.innerHTML;

        function repeatContent(el, till) {
            var html = el.innerHTML;
            var counter = 0; // prevents infinite loop

            while (el.offsetWidth < till && counter < 100) {
                el.innerHTML += html;
                counter += 1;
            }
        }
    }

    // Modals
    $(".modal-f-btn.m1").on("click", function () {
        var kantoorhistoryModal = new bootstrap.Modal(
            document.getElementById("kantoor-history")
        );
        kantoorhistoryModal.show();
    });
    $(".modal-f-btn.m2").on("click", function () {
        var verlofperiodesModal = new bootstrap.Modal(
            document.getElementById("verlofperiodes")
        );
        verlofperiodesModal.show();
    });
    $(".modal-f-btn.m3").on("click", function () {
        var routeandparkingModal = new bootstrap.Modal(
            document.getElementById("routeandparking")
        );
        routeandparkingModal.show();
    });
    $("#data-protection-officer-link").on("click", function () {
        var dataprotectionofficerModal = new bootstrap.Modal(
            document.getElementById("dataprotectionofficer")
        );
        dataprotectionofficerModal.show();
    });

    //Initialize tooltip
    var tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
    );
    var tooltipList = [];
    Array.from(tooltipTriggerList).map(function(name) {
        tooltipList.push(name);
    });
    tooltipList = tooltipList.map(function (tooltipTriggerEl) {
        new bootstrap.Tooltip(tooltipTriggerEl)
    });

    //HAMBURGER MENU
    $(".first-button").on("click", function () {
        $(".animated-icon1").toggleClass("open");
    });

    //top bar dropwdown
    $(".dropdown-toggle[changeable=true]")
        .next(".dropdown-menu")
        .children()
        .on("click", function () {
            $(this)
                .closest(".dropdown-menu")
                .prev(".dropdown-toggle")
                .html($(this).children().html());
            $(".dropdown-toggle")
                .find("img")
                .attr("src", "/assets/sven/img/locatie-plus.png");
            $(".dropdown-item").removeClass("active");
            $(this).find("a").addClass("active");
        });

    // HOVER TEAMMembers style1
    $(".box-team-s1").hover(function () {
        $(this).find(".hidden-part").toggleClass("hidden-hover-vis");
    });

    // same height news cols
    var tabEl = document.querySelectorAll("a.nav-link");

    for (i = 0; i < tabEl.length; i++) {
        tabEl[i].addEventListener("shown.bs.tab", resizeNewsPanels);
    }

    // same height onload
    /*heightRightN = $(".right-part-news").outerHeight();
    heightLeftN = $(".left-part-news").outerHeight();

    if ($(".left-part-news .tab-pane:first .img-news-container").length == 0) {
        heightLeftN += 348;
    }

    if (heightLeftN > heightRightN) {
        var t = heightLeftN - heightRightN;
        var htxt = $(".news-box.main .news-text").outerHeight();
        var finaltxt = htxt - t;
        $(".news-box.main .news-text").css("height", finaltxt);
        $(".news-box.main .news-text").css("overflow", "hidden");
        $(".news-box.main .news-text").addClass("last-rows-opacity");
    } else {
        var t2 = heightRightN - heightLeftN;
        var htxt2 = $(".news-box.main").outerHeight();
        var finaltxt2 = htxt2 + t2;
        $(".news-box.main").css("height", finaltxt2);
    }*/
    resizeNewsPanels();

    // STICKY MENU
    $(window).scroll(function () {
        var sticky = $(".sticky");
        var topbar = $(".top-bar").outerHeight();
        scroll = $(window).scrollTop();
        if (scroll > topbar) {
            sticky.addClass("fixed");
            $(".top-bar").addClass("d-none");
        } else {
            sticky.removeClass("fixed");
            $(".top-bar").removeClass("d-none");
        }
    });

    if ($(window).width() < 767) {
        $(window).scroll(function () {
            var sticky = $(".sticky");
            scroll = $(window).scrollTop();
            if (scroll > 0) {
                sticky.addClass("fixed");
            } else {
                sticky.removeClass("fixed");
            }
        });
    }

    //SLICK CAROUSEL
    $(".kantorvstelling-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        arrows: true,
        dots: true,
    });

    $(".vacatures-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        arrows: true,
        dots: false,
    });
    $(".thema-slider").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        arrows: true,
        dots: false,
        prevArrow: $(".controls-thema .prev"),
        nextArrow: $(".controls-thema .next"),
        responsive: [
            {
                breakpoint: 1199.98,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 849.98,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 574.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
    $(".tv-slider").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        infinite: true,
        arrows: true,
        dots: false,
        prevArrow: $(".controls-tv .prev"),
        nextArrow: $(".controls-tv .next"),
        responsive: [
            {
                breakpoint: 574.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });

    $.cookie('cookie-bar') === 'hide'; // && applyOswaldJs()
    $.cookie('cookie-settings-statistics') === 'true' && applyAnalyticsJs();
    $.cookie('cookie-settings-social') === 'true' && applySocialJs();

    if (location.search.includes('cookie_bypass=1')) {
        contentNavigable = true;
        blockContent();
        cookieBar.hide();
        $('.modal').modal('hide');
    }


    $(".js-sven-navigation").on("click", goToSvenModule);
    $(".js-ajax-showmore-button").on("click", loadMoreImmo);
    $(".js-submit-subscription").on("click", subscribeNewsletter);

    addImmoPlaceholders();
    $(window).resize(addImmoPlaceholders);
    $(window).scroll(changeActiveMenu);
});

$(function () {
    $(".link-public-service").matchHeight();
    $(".mh-t-bnr").matchHeight();
    $(".link-thema").matchHeight();
    $(".box-immo .name-immo").matchHeight();
    // $(".box-immo").matchHeight();
    $(".bottom-box-team-s1 .visible-part").matchHeight();
    $(".mh-info-member-s2").matchHeight();
    $(".box-team-s2").matchHeight();
});

//openstreet map
window.app = window.app || {};
app.contact = {
    init: function () {
        $(".js-address-navigator").on("click", function () {
            var id = $(this).data("id");
            app.contact.reinitMap();
            app.contact.centerMarker(id);
        });
    },
    reinitMap: function () {
        setTimeout(function () {
            app.contact.map.invalidateSize();
        }, 400);
    },
    initMap: function () {
        app.contact.markers = [];
        app.contact.leafbounds = [];

        app.contact.map = L.map("map-canvas").setView([50.85045, 4.34878], 15);
        L.tileLayer("/maps/tile/{s}/{z}/{x}/{y}", {
            foo: "bar",
            attribution:
                'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        }).addTo(app.contact.map);
        //console.log("addressess", addressess);
        _.each(addressess, function (data, key) {
            app.contact.addMarker([data.lat, data.lng], data.icon, data.id);
        });

        if (addressess.length > 0) {
            var center = addressess[0];
            app.contact.map.panTo(new L.LatLng(center.lat, center.lng));
        }
    },
    addMarker: function (latlng, icon, id) {
        var iconmarker = L.icon({
            iconUrl: icon,
            iconSize: [35, 47],
            iconAnchor: [17, 47],
        });
        var marker = L.marker(latlng, { icon: iconmarker }).addTo(
            app.contact.map
        );
        marker.position = latlng;

        app.contact.leafbounds.push(latlng);
        app.contact.markers[id] = marker;
    },
    centerMarker: function (id) {
        var marker = app.contact.markers[id];
        if (marker) {
            app.contact.map.panTo(marker.position);
        }
    },
};

function setLanguage(choice) {
    document.setLangForm.setLangTo.value = choice;
    document.setLangForm.submit();
}

function goToSvenModule(evt) {
    //get id clicked module
    var item = $(evt.currentTarget);
    var href = item.attr('href');
    var type = item.data('type');

    // make pressed button active
    $(".js-sven-navigation").removeClass('active');
    $(item).addClass('active');

    if (href.indexOf('/') == -1) {
        evt.preventDefault();
        var index = $(href).index() / 2;

        //scroll to module
        $('html, body').stop().animate({
            scrollTop: $(href).offset().top - 33
        }, 50 * index, 'easeOutQuad');

        //hide mobile menu when visible
        if ($('.navbar-toggler').is(':visible')) {
            $('.navbar-toggler').trigger('click');
        }
    }

    //ajax count click on navigation
    countClickAjax(type);
}

function countClickAjax(type) {
    if (typeof path_count_click !== 'undefined') {
        $.ajax({
            type: 'POST',
            url: path_count_click,
            dataType: 'json',
            data: {
                type: type
            },
            success: function (result) {
                if (
                    type == 'fednotlink' &&
                    $('.js-fednotlink').attr('href') != '' &&
                    $('.js-fednotlink').attr('href') != '#'
                ) {
                    window.location = $('.js-fednotlink').attr('href');
                }
            }
        });
    }
}

function loadMoreImmo(evt) {
    evt.preventDefault();

    var button = $(evt.currentTarget);
    var url = button.data('ajax');

    countClickAjax('immo');

    var offset = $('#immo').find('.immo-item').length;

    $.ajax({
        method: 'GET',
        url: url.replace('_offset_', offset)
    }).done(function (response) {
        if (response.success) {
            setImmoData(response.immos, button, response.total);
        } else {
            setImmoData([], button);
        }
    });
}

function setImmoData(immos, button, total) {
    var html = '';
    var renderNum = 100;

    immos.forEach(function (item, i) {
        if (i < renderNum) {
            html += item;
        }
    });

    button.parent().before(html);

    var immoItemsNumber = $('#immo').find('.immo-item').length;
    if (immoItemsNumber >= total) {
        button.hide();
    }

    addImmoPlaceholders();

    $('.immo-animate:not(.show)').addClass('show');
}

function addImmoPlaceholders() {
    $('.immo-item.empty').remove();

    var button = $('.js-ajax-showmore-button');
    var immoItemsNumber = $('#immo').find('.immo-item').length;
    var columnNumber = $(window).width() < 576 ? 1 :
        $(window).width() < 992 ? 2 : 3;

    if (columnNumber == 1 || (button.length > 0 && button.is(':visible'))) {
        return;
    }

    var lastRowColumns = immoItemsNumber % columnNumber;
    var numberOfPlaceholders = columnNumber - lastRowColumns;
    if (numberOfPlaceholders >= columnNumber) {
        numberOfPlaceholders = 0;
    }

    for (var i = 0; i < numberOfPlaceholders; i++) {
        $('#immo .button-container').before('<div class="col-lg-4 col-sm-6 col-12 immo-item empty"><div class="box-immo"></div></div>');
    }
}

function subscribeNewsletter() {
    var form = $('.newsletter-form');
    var email = $('#newsletterEmail').val();
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (validateNewsletterEmail(email, reg)) {
        var token = $('.js-token').val();
        $.post(form.data('action'), {email: email, locale: form.data('locale'), token: token}, function (response) {
            if (response.success) {
                $('.js-newsletter').slideUp(1000);
                $('.js-newsletter-thanks').slideDown(1000);
                document.cookie = 'isSubscribedNewsletter=1; expires=Sun, 1 Jan 2030 00:00:00 UTC; path=/'
            }
        }, 'json');

        return false;
    }

    return false;
}

function validateNewsletterEmail(email, reg) {
    var validMail = !(
        email == '' ||
        email == $('.js-input-email').attr('placeholder') ||
        reg.test(email) == false
    );

    if (!validMail) {
        $('.js-email-error').show();
        $('.js-input-email').addClass('c-input--error u-margin-bottom-none').focus();
        $('.js-name-error').show();
    } else {
        if (!validMail) {
            $('.js-email-error').show();
            $('.js-input-email').addClass('c-input--error u-margin-bottom-none').focus();
            $('.js-name-error').hide();
        } else {
            $('.js-name-error').hide();
            $('.js-email-error').hide();
            $('.js-input-email').removeClass('c-input--error u-margin-bottom-none');
        }
    }

    return validMail;
}

function changeActiveMenu() {
    var menuItems = $('.nav-link.js-sven-navigation');
    var currentScroll = $(window).scrollTop();

    menuItems.each(function (index, item) {
        var href = $(item).attr('href');
        var sectionOffset = $(href).offset().top;

        if (currentScroll >= sectionOffset - 300) {
            $(".js-sven-navigation").removeClass('active');
            $(item).addClass('active');
        } else {
            $(item).removeClass('active');
        }
    });

    var startpageSectionOffset = $('#startpage').offset().top;
    if (currentScroll <= startpageSectionOffset + 300) {
        $(".js-sven-navigation").removeClass('active');
    }

    if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
       $(".js-sven-navigation").removeClass('active');
        $('.nav-link.js-sven-navigation:last').addClass('active');
   }
}

function resizeNewsPanels(event) {
    var activated_pane = document.querySelector('.tab-pane.active');
    var heightRightN = $('.right-part-news').outerHeight();
    var heightLeftN = $('.left-part-news').outerHeight();

    if (heightLeftN > heightRightN) {
        var temp =
            heightRightN -
            ($(activated_pane).find('.img-news-container').outerHeight() || 0) -
            $(activated_pane).find('.news-date').outerHeight() -
            parseFloat($(activated_pane).find('.news-date').css('margin-bottom')) -
            $(activated_pane).find('.news-title').outerHeight() -
            parseFloat($(activated_pane).find('.news-title').css('margin-bottom')) -
            $(activated_pane).find('a.main-txt-btn').outerHeight() -
            parseFloat($(activated_pane).find('a.main-txt-btn').css('margin-top')) -
            parseFloat($(activated_pane).find('a.main-txt-btn').css('margin-bottom')) -
            parseFloat($(activated_pane).find('.news-text').css('margin-bottom')) -
            parseFloat($(activated_pane).find('.news-box.main').css('padding-top')) -
            parseFloat($(activated_pane).find('.news-box.main').css('padding-bottom'));

        $(activated_pane).find('.news-box.main .news-text').css('height', temp);
        $(activated_pane).find('.news-box.main .news-text').css('overflow', 'hidden');
        $(activated_pane).find('.news-box.main .news-text').addClass('last-rows-opacity');
    } else {
        var temp = heightRightN - heightLeftN;
        var boxMain = $(activated_pane).find('.news-box.main').outerHeight();
        var bigboxMain = temp + boxMain;
        $(activated_pane).find('.news-box.main').css('height', bigboxMain);

        var temp2 =
            bigboxMain -
            $(activated_pane).find('.news-date').outerHeight() -
            parseFloat($(activated_pane).find('.news-date').css('margin-bottom')) -
            $(activated_pane).find('.news-title').outerHeight() -
            parseFloat($(activated_pane).find('.news-title').css('margin-bottom')) -
            $(activated_pane).find('a.main-txt-btn').outerHeight() -
            parseFloat($(activated_pane).find('a.main-txt-btn').css('margin-top')) -
            parseFloat($(activated_pane).find('a.main-txt-btn').css('margin-bottom')) -
            parseFloat($(activated_pane).find('.news-text').css('margin-bottom')) -
            parseFloat($(activated_pane).find('.news-box.main').css('padding-top')) -
            parseFloat($(activated_pane).find('.news-box.main').css('padding-bottom'));

        $(activated_pane).find('.news-box.main .news-text').css('height', temp2);
    }
}
